import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import '../assets/sass/main.scss';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children } = this.props;
    const { isPreloaded } = this.state;
    return (

          <>
            <Helmet
              title="Ken Bostoen Portfolio"
              meta={[
                { name: 'description', content: 'This is the portfolio of Ken Bostoen' },
                { name: 'keywords', content: 'site, web' },
              ]}
            >
              <html lang="en" className="html_dark"/>
            </Helmet>
            <div className={isPreloaded ? 'main-body is-preload' : 'main-body'}>
              {children}
            </div>
          </>
        )
      }
  }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
