import React from "react"
import { Helmet } from "react-helmet"
import Portfolio from "../components/portfolio/Portfolio"

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ken Bostoen</title>
        <html lang="en" className="html_dark" />
        <meta
          name="description"
          content="Ken bostoen Portfolio Software engineer"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Poppins"
        ></link>
      </Helmet>

      <Portfolio />
    </>
  )
}
