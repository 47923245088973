import "./Skill-progress.scss"
import React from "react"

function SkillProgress() {
  return (
    <div className="progress-circle-container">
      <div>
        <div className="progress-circle progress-100">
          <span>100%</span>
        </div>
        <div className="subtext">Dutch<small> - (Native)</small></div>
      </div>
      <div>
        <div className="progress-circle progress-100">
          <span>100%</span>
        </div>
        <div className="subtext">English<small> - (Fluent)</small></div>
      </div>
      <div>
        <div className="progress-circle progress-75">
          <span>75%</span>
        </div>
        <div className="subtext">French<small> - (Adequate)</small></div>
      </div>
    </div>
  )
}
export default SkillProgress
