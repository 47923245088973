import React, { Component } from "react";
import "./Portfolio.scss"

import Layout from './components/Layout';
import SideBar from './components/SideBar';
// import pic8 from '../assets/images/pic08.jpg';
import Scroll from './components/Scroll';
import Projects from './components/projects/Projects'
import Skills from './components/skills/Skills'
import Contact from './../contact/Contact'

export default class Portfolio extends Component {
  sections = [
    { id: 'top', name: 'Intro', icon: 'fa-home' },
    { id: 'about', name: 'About Me', icon: 'fa-user' },
    { id: 'skills', name: 'Skills', icon: 'fa-laptop' },
    { id: 'achievements', name: 'Achievements', icon: 'fa-trophy' },
    { id: 'portfolio', name: 'Portfolio', icon: 'fa-th' },
    { id: 'contact', name: 'Contact', icon: 'fa-envelope' },
  ];
  projectsList = [
    {
        customer: 'Smals',
        projectName: 'VIDIS gezondheidszorg',
        startdate: new Date('12/1/2020'),
        enddate: new Date(),
        startdateName: 'December 2020',
        enddateName: 'Now',
        imageURL: '../egezondheid.svg',
        description: 'The existing application of <span>e-health</span> to display your medicationscheme needed to be rebuilt <span>from scratch</span> since they wanted to merge <span>multiple data-sources</span> together. This is an <span>angular application</span> working on a <span>pre-existing java backend</span>. The user (patient or caretaker) should be able to see the medicationscheme of the subject and zoom in on specific areas. Also there are diary notes that can be added and checked. Since this data is taken from multiple external sources and the backend has <span>strict rules</span> about not saving any data, the frontend needs to handle all of the business logic. To ensure these <span>complex logic</span> keeps working correctly, a <span>high amount of test coverage</span> is required.',
        role: '<span>Frontend developer:</span> I joined this project 2 months after the start and helped build out various things of the application. After a few months the project manager decided that I could <span>carry the frontend part myself</span> and make sure the release (a few weeks later) would go smoothly. Since my co-frontend-dev was moved to another team, I <span>completed the release</span> without big issues. After the release i took the time to add some <span>major reworks</span> to the old code that was implemented before my arrival. Things like implementing a <span>redux store</span> and merging the old and new model systems together were some of the tasks i decided to work on while still <span>delivering sprints</span> at the same velocity.',
        technologies: ['Typescript', 'Angular', 'Redux', 'NgRx', 'Jest', 'Cypress', 'Jenkins']
    },
    {
        customer: 'ASML',
        projectName: 'Bill of materials visualiser',
        startdate: new Date('3/1/2020'),
        enddate: new Date('12/1/2020'),
        startdateName: 'March 2020',
        enddateName: 'December 2020',
        imageURL: '../ASML.jpg',
        description: 'I was hired to rebuild and <span>merge two old coffeescript applications</span> into a new one using the <span>latest Angular version</span>. The existing frontend team consisted of 2 developers who had no experience using Angular. This was a <span>greenfield</span> application so <span>planning</span> was of critical importance. Things like making <span>proof of concepts</span> for various <span>graphing libraries</span> was essential for a future-proof application. Since the <span>ngrx store</span> was a new concept within the team they wanted to use it to its <span>max potential</span> by integrating it with the Graphing Library of our choice. Using sprints of 3 weeks we <span>analysed and implemented</span> many stories with successful results.',
        role: 'My task was <span>creating, analyzing and implementing frontend stories</span>, communicating with backend developers who were working on the <span>already existing backend</span> and testing to make sure everything behaved as needed.',
        technologies: ['Typescript', 'Angular', 'D3.js', 'Redux', 'Karma', 'Jasmine', 'Jenkins']
    },
    {
        customer: 'Ordina',
        projectName: 'Cad sales',
        startdate: new Date('1/1/2020'),
        enddate: new Date('3/1/2020'),
        startdateName: 'January 2020',
        enddateName: 'March 2020',
        imageURL: '../cad.jpg',
        description: 'A customer requested an application which made an <span>integrated sales environment</span> of a CAD file. The sales person needed to be able to select convention stands and <span>assign a client</span> to it',
        role: '<span>Fullstack developer</span>: Started working on this to <span>help out</span> a new junior developer. Since the project manager saw the sudden <span>velocity increase</span> he asked for me to <span>stay longer</span> untill the contract for my next client started.',
        technologies: ['Typescript', 'Angular', 'NestJS', 'AWS', 'DynamoDB']
    },
    {
        customer: 'Liantis',
        projectName: 'Accounting application',
        startdate: new Date('4/1/2019'),
        enddate: new Date('1/1/2020'),
        startdateName: 'April 2019',
        enddateName: 'January 2020',
        imageURL: '../liantis.png',
        description: 'Working on a <span>web platform for accountants and their customers</span> to manage taxes and payments. Development in an environment of <span>15 microservices</span> of which 12 are shared with other teams.',
        role: '<span>Fullstack developer</span> in a team of 4 Fullstack developers. We were fully responsible for our application. We received analysed stories, <span>implemented them and deployed</span> everything ourselves through <span>Jenkins</span>. Through versioning, <span>backwards compatibility</span> and <span>good communication</span> we made sure that changes in shared microservices didn\'t cause errors for other teams. Since we didn\'t have testers, </span>writing our own tests was very important. By writing <span>extensive amounts of unit tests</span> and using tools like <span>SonarQube and Saucelabs</span> we guarded the quality of our application.',
        technologies: ['Angular', 'Redux', 'NgRx', 'Karma', 'Jasmine', 'Protractor', 'Java Spring boot', 'Microservices', 'MongoDB', 'RabbitMQ', 'Docker', 'Jenkins']
    },
    {
        customer: 'Fednot',
        projectName: 'Biddit (www.biddit.be)',
        startdate: new Date('10/1/2017'),
        enddate: new Date('6/1/2019'),
        startdateName: 'October 2017',
        enddateName: 'June 2019',
        imageURL: '../biddit.jpg',
        description: 'Fednot wanted to have an <span>interactive bidding platform</span> for public sales of properties. I became part of a <span>french/dutch/english</span> development environment.',
        role: `As part of the <span>initial setup team</span> of 8 developers, I helped setup the project.
        An <span>Angular-Java spring boot microservice</span> environment was created.
        After a couple of months, the lead frontend developer was assigned to a different project. 
        Since I had a <span>great attitude and ownership</span> I was the perfect candidate to become the <span>new Frontend Lead</span>.
        I <span>guarded the quality</span> of the code and application for the next year, <span>guided the new developers</span> through the code. I mainly took on bigger stories like <span>server side rendering</span>, version upgrades and working with the google location API.
        The tasks in this project were very <span>end to end</span> (frontend, backend, testing).
        With some basic analysis <span>we designed and implemented the stories</span>, testing the stories after deploying to a staging environment and have a <span>monthly production deploy</span>.
        Using a <span>Kubernetes cluster on google cloud</span> we made sure the project was <span>stable and scaled</span> appropriately.`,
        technologies: ['Angular', 'Redux', 'NgRx', 'Karma', 'Jasmine', 'Protractor', 'Java Spring boot', 'CQRS', 'Event sourcing', 'Microservices', 'MongoDB', 'RabbitMQ', 'Docker',
            'Google Cloud']
    },
    {
        customer: 'ToThePoint',
        projectName: 'Attune',
        startdate: new Date('9/1/2017'),
        enddate: new Date('10/1/2017'),
        startdateName: 'September 2017',
        enddateName: 'October 2019',
        imageURL: '../attune.svg',
        description: 'A management program for <span>managing consultants</span> and customer contracts.',
        role: `Worked in a team of 8 Junior Fullstack Developers and 2 Seniors.
        This project used things like <span>event driven architecture</span>, <span>CQRS</span> (Command and Query Responsibility Segregation).`,
        technologies: ['Angular', 'Redux', 'RxJs', 'Java spring boot', 'CQRS', 'Event sourcing', 'Microservices', 'MongoDB', 'RabbitMQ', 'Docker']
    },
]
sideProjects = [
  {
    customer: '',
    projectName: 'Budgetting tool',
    startdate: undefined,
    enddate: undefined,
    startdateName: '',
    enddateName: '',
    imageURL: '../Budgetting.jpg',
    description: '<p>A <span>personal budgetting tool</span> inspired by many <span>paid variants</span> online. I made this because I didn\'t want to pay for one and keep <span>my data 100% private and secure</span>. I worked on this project on and off for about <span>6 months</span> and it\'s development is <span>still ongoing</span> but I\'m using it on a daily basis</p>',
    role: `This project is made using Angular, NgRx, RxJS and Firebase for authentication and data storage`,
    technologies: ['Angular', 'RxJs', 'Redux', 'Firebase auth & store']
},  {
  customer: '',
  projectName: 'Pathfinder visualiser',
  startdate: undefined,
  enddate: undefined,
  startdateName: '',
  enddateName: '',
  imageURL: '../pathfinder.png',
  description: ' <p>A project to <span>visualise</span> different kinds of <span>pathfinding algorithms</span>. I made this project because I am <span>interested in how these algorithms work</span>, thats why i decided to learn it and implement <span>my own version</span>. You can draw walls, weighted nodes and select what algorithm you want to visualise. Then by <span>pressing Start</span> in the top left corner it will run using your own created maze.</p>',
  link: 'http://kenpathfinder.netlify.com',
  technologies: ['Angular', 'RxJs']
},{
  customer: '',
  projectName: 'Sorting visualiser',
  startdate: undefined,
  enddate: undefined,
  startdateName: '',
  enddateName: '',
  imageURL: '../sorting.png',
  description: '<p>A <span>small project</span> to learn how <span>sorting algorithms</span> are implemented. I made this only for <span>personal learning</span> purposes. Move the slider to adjust the amount of nodes and press start to see how everything gets sorted.</p>',
  link: 'http://kensorting.netlify.com',
  technologies: ['Angular', 'RxJs']
},
]
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <Layout>
        <SideBar sections={this.sections} />

        <div id="main">
          <section id="top" className="one dark cover">
            <div className="container">
              <header>
                <h2 className="alt">
                  Hi! I'm <strong className="highlight">Ken Bostoen</strong>
                  <br />
              Software engineer
            </h2>
                <p>I have made few things, check it out.</p>
              </header>

              <footer>
                <Scroll type="id" element={'about'}>
                  <a href="#about" className="button">
                    Show me
              </a>
                </Scroll>
              </footer>
            </div>
          </section>
          <section id="about" className="two dark">
            <div className="container">
              <header>
                <h2><strong>About Me</strong></h2>
              </header>

              {/* <a href="/#" className="image featured"> */}
                {/* <img src={biddit} alt="" /> */}
              {/* </a> */}

                <p>
                  As a youngling, I’ve always dreamt of becoming a video game
                  developer, which gradually led me to the world of IT. Initially, I
                  started out as a backend developer, but thanks to a school project I
            got to know <span className="highlight">Angular</span> and it became love at first sight.
          </p>
                <p>
                  I then realised that a brilliant <span className="highlight">backend</span> will never shine like it’s
            supposed to without an even better frontend. Having a key eye in
            noticing how front- and backend are supposed to work together, I’ve
            decided to focus on becoming a <span className="highlight">fullstack</span> developer with a <span className="highlight">higher expertise in frontend</span>. My current
            favourite tech stack is <span className="highlight">Angular/Vue + Java Spring Boot + Mongodb</span>,
            but this could change in a heartbeat when the opportunity arises.
          </p>
            </div>
          </section>
          <section id="skills" className="three dark">
          <div className="container">
              <header>
                <h2><strong>Skills</strong></h2>
              </header>
              <div className="row">
                <Skills></Skills>
              </div>
            </div>
          </section>
          <section id="achievements" className="four dark">
          <div className="container">
              <header>
                <h2><strong>Achievements</strong></h2>
              </header>
              <div id="achievements">
                <div className="prizes">
               <h3 className="icon fa-trophy">&nbsp; Prizes</h3>
               <p>
              <span className="highlight">Liantis:</span> Digital innovation Award{" "}
              <a
                href="https://www.liantis.be/nl/nieuws/Liantis-valt-in-de-prijzen"
                target="_blank" rel="noreferrer"
              >
                (link)
              </a>
            </p>
            <p>
              <span className="highlight">Biddit:</span> Best customer experience Award{" "}
              <a
                href="https://www.notaris.be/nieuws-pers/detail/federatie-van-het-notariaat-wint-it-award#:~:text=In%20de%20categorie%20'best%20customer,september%202018%20volop%20werd%20uitgerold."
                target="_blank" rel="noreferrer"
              >
                (link)
              </a>
            </p>
                </div>
                <div className="teaching">
               <h3>Teaching &nbsp;<span className="icon fa-book"></span></h3>
               <p>Speaker at <span className="highlight">VueJS Antwerp</span> (topic: NgRx vs VueX)</p>
            <p>2018 & 2019 2TP <span className="highlight">Angular Bootcamp</span> teacher</p>
    
                </div>
              </div>
            </div>
          </section>
          <section id="portfolio" className="five dark">
            <div className="container">
              <header>
                <h2><strong>Portfolio</strong></h2>
              </header>
              <div className="row">
                <Projects projects={this.projectsList}></Projects>
                <h3>Side projects</h3>
                <Projects projects={this.sideProjects} sideProject={true}></Projects>
              </div>
            </div>
          </section>
          <section id="contact" className="six dark">
            <div className="container">
              <header>
                <h2><strong>Contact</strong></h2>
              </header>

              <p>
                Feel free to contact me here or through direct message on LinkedIn.
          </p>

              <Contact></Contact>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
