import "./Skills.scss"
import React from "react"
import SkillProgress from "../skill-progress/Skill-progress"

function Skills() {
    return (
        <div className="skills-overview">
          <div>
            <SkillProgress></SkillProgress>
          </div>
          <div className="tab-wrapper">
            <div className="tab">
              <h4>Frontend</h4>
              <hr/>
            <ul>
                <li className="icon fa-check">Javascript - Typescript</li>
                <li className="icon fa-check">Angular 2+</li>
                <li className="icon fa-check">VueJS</li>
                <li className="icon fa-check">ReactJS</li>
                <li className="icon fa-check">Redux</li>
                <li className="icon fa-check">Bootstrap</li>
              </ul>
            </div>
            <div className="tab">
              <h4>Backend</h4>
              <hr/>
            <ul>
                <li className="icon fa-check">Java (Spring Boot)</li>
                <li className="icon fa-check">NodeJS</li>
                <li className="icon fa-check">RabbitMQ</li>
                <li className="icon fa-check">MongoDB - SQL</li>
                <li className="icon fa-check">Microservices</li>
                <li className="icon fa-check">CQRS - Event sourcing</li>
              </ul>
            </div>
            <div className="tab">
             <h4>Tools</h4>
             <hr/>
            <ul>
                <li className="icon fa-check">Docker</li>
                <li className="icon fa-check">Kubernetes</li>
                <li className="icon fa-check">Google cloud</li>
                <li className="icon fa-check">Intellij / VsCode</li>
                <li className="icon fa-check">Jenkins / Gitlab</li>
                <li className="icon fa-check">Jira</li>
              </ul>
            </div>
          </div>
            
          </div>)
}
export default Skills;
