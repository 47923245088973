module.exports = {
  siteTitle: 'Ken Bostoen Portfolio',
  authorName: 'Ken Bostoen',
  heading: 'Software engineer',
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/kenbostoen',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/ken-bostoen-53712413b/',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:ken.bostoen@hotmail.com',
    },
  ],
};
