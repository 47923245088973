import "./Projects.scss"
import React from "react"
import moment from "moment"
import ReactModal from 'react-modal'
import { FaTimes } from 'react-icons/fa';


ReactModal.setAppElement('#___gatsby')
export class Projects extends React.Component {
    constructor() {
        super();
        this.state = {
            modalIsOpen: undefined
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }


    getTechBackgroundColor(tech) {
        const frontend = ['typescript', 'angular', 'redux', 'ngrx', 'karma', 'jasmine', 'protractor', 'jest', 'cypress', 'd3.js'];
        const backend = ['java spring boot', 'cqrs', 'event sourcing', 'microservices', 'nestjs'];
        const other = ['jenkins', 'rabbitmq', 'docker', 'mongodb', 'dynamodb', 'aws', 'google cloud'];
        if (frontend.includes(tech.toLowerCase())) {
            return '#004731'
        } else if (backend.includes(tech.toLowerCase())) {
            return '#002248'
        } else if (other.includes(tech.toLowerCase())) {
            return '#26050a'
        }
        return 'red';
    }

    getTechPills(tech) {
        const techList = []
        tech.forEach(t => {
            techList.push(<span className="tech-pill" style={{ 'backgroundColor': this.getTechBackgroundColor(t) }} key={t}>{t}</span>);
        })
        return techList;
    }
    getTimeSpentOnProject = (startdate, enddate) => {
        var projectstartdate = moment(startdate)
        var projectenddate = moment(enddate)
        const years = projectenddate.diff(projectstartdate, "years")
        projectstartdate.add(years, "years")
        const months = projectenddate.diff(projectstartdate, "months")
        projectstartdate.add(months, "months")

        const yearName = years > 1 ? "years" : "year"
        const monthName = months > 1 ? "months" : "month"

        if (years > 0) {
            return (
                <span>
                    ({years} {yearName} and {months} {monthName})
                </span>
            )
        } else {
            return (
                <span>
                    ({months} {monthName})
                </span>
            )
        }
    }

    getModal() {

    }

    openModal(project) {
        this.setState({ modalIsOpen: project });
    }

    closeModal() {
        this.setState({ modalIsOpen: undefined });
    }

    isNotSame(a, b){
        return a !== b
    }

    render() {
        let role = ('');
        let link = ('');
        if(this.props.sideProject) {
            if(this.state && this.state.modalIsOpen && this.state.modalIsOpen.link) {
                link = (<span> - <a className="highlight" href={this.state.modalIsOpen.link} target="_blank" rel="noreferrer">Link</a></span>)
            }
        } else {
            role = (<div><h3 className="icon fa-comment">My role</h3>
                <p className="modal-text" dangerouslySetInnerHTML={{ __html: this.state.modalIsOpen ? this.state.modalIsOpen.role : '' }}></p>
            </div>)
        }
        return (
            <div className="projects" id="project-list">
                {this.props.projects.map(project => {
                    let cardText;
                    if (this.props.sideProject === true) {
                        cardText = (<div className="title-box">
                            <h3>{project.projectName}</h3>
                        </div>)
                    } else {
                        cardText = (<div className="title-box">
                            <h3>{project.projectName}</h3>
                            <p>{project.startdateName} - {project.enddateName} </p> <p>{this.getTimeSpentOnProject(project.startdate, project.enddate)}</p>
                        </div>)
                    }
                    return (
                        <div className="project-tile" role="button" key={project.projectName} onClick={() => this.openModal(project)} onKeyDown={() => this.openModal(project)}>
                            <img alt={'project_' + project.projectName} src={project.imageURL} ></img>
                            {cardText}
                        </div>
                    )
                })}
                <ReactModal
                    isOpen={!!this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Example Modal In Gatsby"
                >
                    <h2>{this.state.modalIsOpen ? this.state.modalIsOpen.projectName : ''} {link} <small>{this.state.modalIsOpen && this.isNotSame(this.state.modalIsOpen.startdate, this.state.modalIsOpen.enddate) ? (this.getTimeSpentOnProject(this.state.modalIsOpen.startdate, this.state.modalIsOpen.enddate)) : ''}</small></h2>
                    {this.state.modalIsOpen &&  this.state.modalIsOpen.customer? (<h3 className="icon fa-user">{this.state.modalIsOpen.customer}</h3>): ('')}
                    <h3 className="icon fa-book">Short description</h3>
                    <p className="modal-text" dangerouslySetInnerHTML={{ __html: this.state.modalIsOpen ? this.state.modalIsOpen.description : '' }}></p>
                    {role}
                    <h3 className="icon fa-laptop">Technology used</h3>
                    <div className="tech-wrapper">
                        {this.getTechPills(this.state.modalIsOpen ? this.state.modalIsOpen.technologies : [])}
                    </div>
                    <span role="button" className="close-button" onClick={this.closeModal} onKeyDown={this.closeModal}><FaTimes /></span>
                </ReactModal>
            </div>)
    }
}

export default Projects;
